<template>
  <div>
    <template v-if="!this.isReportCardCreated">
      <p class="p-4 text-center text-sm">
        {{
          this.text_formatter($t('grading.save_assessments_and_grades_first'))
        }}
      </p>
    </template>
    <template v-else>
      <v-select
        :options="specialUpdatesOptions"
        label="title"
        :reduce="(s) => s.value"
        v-model="updateType"
        class="md:w-1/2 my-10 mx-auto w-full"
        :clearable="false" />

      <updateATLForm v-if="isATLUpdates" />
      <updateIDUForm v-if="isIDUSAAUpdate" />
      <updateParticipation v-if="isTheParticipationUpdate" />
      <updateCAS v-if="isTheCASTOKEEUpdate" />
      <updatePOI v-if="isPOIUpdate" />
    </template>
  </div>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

// components
import updateATLForm from './updateATLForm.vue'
import updateIDUForm from './updateIDUForm.vue'
import updateParticipation from './updateParticipation.vue'
import updateCAS from './UpdateCAS.vue'
import updatePOI from './updatePOI'

export default {
  name: 'special-updates',
  mixins: [gradingMixins],
  components: {
    updateATLForm,
    updateIDUForm,
    updateParticipation,
    updateCAS,
    updatePOI
  },
  data() {
    return {
      specialUpdatesOptions: [],
      updateType: null,
      ////////////////////////
      // / Data
      ///////////////////////
      ATLOptions: [],
      ATLCategories: []
    }
  },
  computed: {
    isATLUpdates() {
      return this.updateType === 'update_ATL'
    },
    isIDUSAAUpdate() {
      return this.updateType === 'update_IDU_SAA'
    },
    isTheParticipationUpdate() {
      return this.updateType === 'update_the_participation'
    },
    isTheCASTOKEEUpdate() {
      return this.updateType === 'update_the_CAS_TOK_EE'
    },
    isPOIUpdate() {
      return this.updateType === 'update_poi'
    }
  },
  methods: {
    /////////////////////////////
    // APIs
    /////////////////////////////
    getATLOptions() {
      ReportCardRepo.getATLOptions()
        .then((res) => {
          this.ATLOptions = res.data
        })
        .catch(this.errMsg)
    },
    getATLCategories() {
      ReportCardRepo.getATLCategories()
        .then((res) => {
          this.ATLCategories = res.data
        })
        .catch(this.errMsg)
    },
    /////////////////////////////
    // HandleShowingUpdatesOptions
    /////////////////////////////
    handleShowingUpdatesOptions() {
      if (this.activeRoundGradingId) {
        const round = this.activeRound
        if (round.include_ATL_in_the_report_card) {
          this.getATLOptions()
          this.getATLCategories()
          this.specialUpdatesOptions.push({
            title: this.$t('grading.update_ATL'),
            value: 'update_ATL'
          })
        }

        if (round.include_IDU_in_the_report_card) {
          this.specialUpdatesOptions.push({
            title: this.$t('grading.update_IDU_SAA'),
            value: 'update_IDU_SAA'
          })
        }
        if (round.include_the_participation_in_the_report_card) {
          this.specialUpdatesOptions.push({
            title: this.$t('grading.update_The_participation'),
            value: 'update_the_participation'
          })
        }
        if (round.include_CAS_TOK_EE_in_the_report_card) {
          this.specialUpdatesOptions.push({
            title: this.$t('grading.update_the_CAS_TOK_EE'),
            value: 'update_the_CAS_TOK_EE'
          })
        }
        if (round.include_POI_in_the_report_card) {
          this.specialUpdatesOptions.push({
            title: this.$t('grading.update_poi'),
            value: 'update_poi'
          })
        }
        if (this.specialUpdatesOptions.length)
          this.updateType = this.specialUpdatesOptions[0].value
        // {
        //   title:this.$t('grading.update_The_Engagement_PYP_ATL'),
        //   value:'update_The_Engagement_PYP_ATL'
        // }
      }
    }
  },
  mounted() {
    this.handleShowingUpdatesOptions()
  }
}
</script>
<style lang="scss">
.guide {
  .bold {
    font-weight: 600;
  }
  .bolder {
    font-weight: 900;
  }
  &-title {
    border-bottom: 2px solid rgba(var(--vs-primary), 0.5);
  }
  &-details {
    li {
      display: flex;
      padding-bottom: 8px;
      padding-top: 10px;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--vs-primary), 0.4);
      }
    }
  }
}
</style>
