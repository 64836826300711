<template>
  <div>
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>
    <h5 class="text-center mb-3">
      {{ $t('grading.update_ATL') }}
    </h5>
    <!-- Table -->
    <vs-table
      class="m-auto h-full"
      :data="atl_data"
      :hoverFlat="true"
      :noDataText="''"
      id="atl-update-table">
      <!-- Header -->
      <template slot="thead">
        <vs-th v-for="(o, i) in ATLCategories" :key="'o_h_' + i">
          {{ o.name }}</vs-th
        >
      </template>
      <!-- Rows -->
      <template>
        <vs-tr>
          <vs-td v-for="(d, i) in atl_data" :key="i">
            <v-select
              :options="ATLOptions"
              label="name"
              :reduce="(o) => o.id"
              v-model="d.atl_option_id"
              class="w-full"
              :clearable="false"
              :placeholder="$t('form.choose_grade')"
              :showLoadingIcon="loadingOptions" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- guide  -->
    <div class="guide mt-20 mb-10">
      <div class="guide-title flex py-3 text-center">
        <h6 class="w-1/5 text-center text-primary">
          {{ $t('grading.grade') }}
        </h6>
        <h6 class="w-4/5 text-center text-primary">
          {{ $t('grading.descriptor') }}
        </h6>
      </div>
      <ul class="guide-details">
        <li v-for="(g, i) in ATLOptions" :key="i">
          <h6 class="w-1/5 text-center bold">{{ g.name }}</h6>
          <p class="w-4/5">{{ g.descriptor }}</p>
        </li>
      </ul>
    </div>

    <!-- Actions -->
    <vs-button
      class="vs-con-loading__container mt-10 mx-auto block"
      @click="saveData"
      ref="submitLoader"
      id="submitLoader"
      :disabled="loadSubmit"
      >{{ $t('grading.click_to_save_after_teacher_review') }}
    </vs-button>
  </div>
</template>
<script>
// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name: 'update-atl-form',
  mixins: [gradingMixins],

  data() {
    return {
      loadingOptions: false,
      ATLOptions: [],
      ATLCategories: []
    }
  },
  computed: {
    atl_data: {
      get() {
        return this.reportCardData?.reportCaredAtls || []
      },
      set(v) {
        if (this.reportCardData.id)
          this.reportCardData = {...this.reportCardData, reportCaredAtls: v}
        else this.reportCardData = {reportCaredAtls: v}
      }
    }
  },
  methods: {
    /////////////////////
    // APIs
    ////////////////////
    getATLOptions() {
      this.loadingOptions = true
      ReportCardRepo.getATLOptions()
        .then((res) => {
          this.ATLOptions = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingOptions = false
        })
    },
    getATLCategories() {
      this.startLoadingDialog()

      ReportCardRepo.getATLCategories()
        .then((res) => {
          this.ATLCategories = res.data
          const atlData = []
          this.ATLCategories.forEach((e) => {
            const obj = {
                atl_category_id: e.id,
                atl_option_id: null
              },
              matchedATLData =
                this.atl_data.find(
                  (a) => (e.atl_category_id || e.id) === a.atl_category_id
                ) || null

            if (!matchedATLData) this.atl_data.push(obj)
          })
          // this.atl_data = atlData
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    saveData() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveATLUpdates()
        } else {
          this.errMsg()
        }
      })
    },
    saveATLUpdates() {
      this.startLoadingSubmit()
      const atl_data = () => {
          const data = this.atl_data.filter((e) => e.atl_option_id)
          if (data.some((e) => e.atlCategory?.id && !e.atl_category_id))
            return data.map((e) => ({
              atl_option_id: e.atl_option_id,
              atl_category_id: e.atlCategory?.id
            }))
          else return data
        },
        payload = {
          user_id: this.selectedStudentUserId,
          round_id: this.activeRoundGradingId,
          subject_id: this.selectedSubjectId,
          atl_data: atl_data()
        }
      ReportCardRepo.saveATLUpdates(payload)
        .then(() => this.successMsg(this.$t('form.saved_successfully')))
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    }
  },
  created() {
    this.getATLOptions()
    this.getATLCategories()
  }
}
</script>
<style lang="scss">
#atl-update-table {
  .vs-con-tbody {
    border: 1px solid rgba(var(--vs-primary), 0.2);
    min-height: 34vh;
    background: #fff;
  }
  th {
    color: rgba(var(--vs-primary), 1);
  }
}
</style>
