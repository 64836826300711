<template>
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>

    <template v-if="!this.isReportCardCreated">
      <p class="p-4 text-center text-sm">
        {{
          this.text_formatter($t('grading.save_assessments_and_grades_first'))
        }}
      </p>
    </template>
    <template v-else>
      <template v-if="isCoordinator">
        <v-select
          :options="commentsOptions"
          label="title"
          :reduce="(c) => c.value"
          v-model="commentOf"
          class="md:w-1/2 mt-10 mx-auto w-full"
          :clearable="false" />
      </template>
      <div class="mt-10">
        <div class="w-full" v-if="commentOf === 'teacher'">
          <div v-if="showTeacherComments">
            <ul class="w-full mb-20">
              <li
                class="vx-row mb-4 items-center"
                v-for="(res, i) in selectedStudentResources"
                :key="'res_' + i">
                <p class="vx-col md:w-1/6 w-full truncate">{{ res.name }}</p>
                <p class="vx-col md:w-1/6 w-1/2">{{ res.dateFormatted2 }}</p>
                <p class="vx-col md:w-1/6 w-1/2">
                  {{ res.type | text_formatter }}
                </p>
                <p class="vx-col md:w-1/2 w-full text-sm comment">
                  <template v-if="res.studentNotes">
                    <span
                      class="mb-2"
                      v-for="(sn, sni) in res.studentNotes"
                      :key="'note_' + sni"
                      >{{ sn.note }}</span
                    >
                  </template>
                  <span v-else>-</span>
                </p>
              </li>
            </ul>

            <div class="">
              <h5 class="text-center mb-3">
                {{ $t('grading.teacher_comment') }}
              </h5>
              <vs-textarea
                height="150px"
                counter="400"
                :label="$t('grading.write_a_comment_Maximum_400_Characters')"
                :counter-danger.sync="counterDanger"
                v-model="teacherComment" />
            </div>
          </div>
          <p v-else class="text-center text-danger mt">
            {{ $t('grading.not_available_for_this_time') }}
          </p>
        </div>

        <div class="w-full" v-else>
          <div v-if="showCoordinatorComments">
            <div class="">
              <h5 class="text-center mb-3">
                {{ $t('grading.section_coordinator_comment') }}
              </h5>
              <vs-textarea
                height="300px"
                counter="700"
                :label="$t('grading.write_a_comment_Maximum_700_Characters')"
                :counter-danger.sync="counterDanger"
                v-model="coordinatorComment" />
            </div>
          </div>
          <p v-else class="text-center text-danger">
            {{ $t('grading.not_available_for_this_time') }}
          </p>
        </div>
      </div>
      <vs-button
        class="vs-con-loading__container mt-2 mx-auto block"
        @click="saveComment()"
        v-if="showTeacherComments || showCoordinatorComments"
        ref="submitLoader"
        id="submitLoader"
        :disabled="disableSubmit"
        >{{ $t('grading.click_to_save_after_teacher_review') }}
      </vs-button>
    </template>
  </div>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

export default {
  name: 'comments-and-feedback',
  mixins: [gradingMixins],
  data() {
    return {
      counterDanger: false,

      commentOf: 'teacher'
    }
  },
  watch: {},

  computed: {
    showCoordinatorComments() {
      return Boolean(
        this.activeRound?.allow_section_coordinator_to_update_a_General_comment
      )
    },
    showTeacherComments() {
      return Boolean(
        this.activeRound?.allow_teachers_to_update_a_subject_based_comment
      )
    },
    teacherComment: {
      get() {
        return this.reportCardData?.teacher_comment?.note || ''
      },
      set(v) {
        this.reportCardData.teacher_comment.note = v
      }
    },
    coordinatorComment: {
      get() {
        return this.reportCardData?.section_coordinator_comment?.note || ''
      },
      set(v) {
        this.reportCardData.section_coordinator_comment.note = v
      }
    },
    disableSubmitTeacherComment() {
      return this.commentOf === 'teacher' && !this.teacherComment
    },
    disableSubmitCoordinatorComment() {
      return this.commentOf === 'coordinator' && !this.coordinatorComment
    },
    disableSubmit() {
      return (
        this.loadSubmit ||
        this.disableSubmitTeacherComment ||
        this.disableSubmitCoordinatorComment
      )
    },
    commentsOptions() {
      const teacherOption = {
          title: this.$t('grading.teacher_comment'),
          value: 'teacher'
        },
        coordinatorOption = {
          title: this.$t('grading.section_coordinator_comment'),
          value: 'coordinator'
        }
      return this.isCoordinator
        ? [teacherOption, coordinatorOption]
        : [teacherOption]
    }
  },
  methods: {
    //////////////////////
    // APIs
    //////////////////////

    saveComment() {
      const commentOfTeacher = this.commentOf === 'teacher',
        endpoint = commentOfTeacher
          ? 'saveTeacherComment'
          : 'saveCoordinatorComment',
        payload = {
          user_id: this.selectedStudentUserId,
          round_id: this.activeRoundGradingId,
          note: commentOfTeacher ? this.teacherComment : this.coordinatorComment
        }

      // Abort
      if (commentOfTeacher && !this.teacherComment) return
      if (!commentOfTeacher && !this.coordinatorComment) return

      // Continuo
      if (commentOfTeacher) payload.subject_id = this.selectedSubjectId
      this.startLoadingSubmit()
      ReportCardRepo[endpoint](payload)
        .then(() => this.successMsg())
        .catch((err) => this.errMsg(err, this.$t('grading.save_comment')))
        .finally(this.endLoadingSubmit)
    }
    /////////////////////
  },
  created() {
    if (!this.reportCardData) this.reportCardData = {}

    if (!this.reportCardData?.teacher_comment) {
      this.reportCardData.teacher_comment = {note: ''}
    }
    if (!this.reportCardData.section_coordinator_comment)
      this.reportCardData.section_coordinator_comment = {note: ''}
  }
}
</script>
<style scoped>
.comment {
  background-color: #e3e3e3;
  color: #000;
  padding: 7px 16px;
}
</style>
