<template>
  <vx-card headerBackgroundColor="#f9f9f9" :showLoading="loadingCard">
    <template v-slot:header>
      <div
        class="flex justify-between mb-4 items-center md:flex-no-wrap flex-wrap">
        <v-select
          :options="studentsOfSelectedClass"
          label="name"
          @change="changeStudent"
          v-model="selectedStudent"
          class="w-full md:w-1/3"
          :placeholder="$t('form.choose_student')"
          :showLoadingIcon="loadingStudents"
          v-if="studentsOfSelectedClass.length" />
        <h5 class="md:w-1/3 w-full" v-else>{{ studentName }}</h5>
        <p
          class="w-full lg:w-1/3 md:w-1/2 my-2 mx-auto md:text-center text-left">
          {{ className }}
        </p>
        <div class="w-full md:mb-0 mb-4 lg:w-1/3 md:w-1/2">
          <v-select
            :options="allocationSubjects"
            label="name"
            :reduce="(s) => s.id"
            v-model="selectedSubjectId"
            @change="changeSubject"
            class="w-full"
            :placeholder="$t('form.choose_subject')"
            :clearable="false"
            :disabled="loadingCard || !allocationSubjects.length"
            v-if="allocationSubjects.length"
            :showLoadingIcon="loadingSubjects" />
        </div>
      </div>
    </template>
    <div class="p-4">
      <template v-if="isRoundActive">
        <div
          class="mx-auto md:w-1/2 w-full flex md:flex-no-wrap flex-wrap mb-10"
          :class="activeRound.term ? ' justify-between' : ' justify-center'">
          <h6 v-if="activeRound.term">{{ activeRound.term }}</h6>
          <h6 :class="{'text-danger': activeRoundRestDays < 3}">
            {{ activeRoundRestDays }}
            {{ $t('grading.days_to_close_the_review_period') | text_formatter }}
          </h6>
        </div>
        <vs-tabs alignment="center" id="update-card-tabs" v-if="showTabs">
          <vs-tab :label="$t('grading.assessments_and_grades')">
            <div class="con-tab-ejemplo">
              <assessments-and-grades
                :showCancelBtn="false"
                :reloadData.sync="reloadData"
                :showManualUpdate="false" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('grading.comments_and_feedback')"
            :disabled="!activeRoundGradingId">
            <div class="con-tab-ejemplo">
              <commentsAndFeedback />
            </div>
          </vs-tab>
          <vs-tab
            :label="$t('grading.special_updates')"
            v-if="showSpecialUpdates">
            <div class="con-tab-ejemplo">
              <specialUpdates />
            </div>
          </vs-tab>
          <vs-tab :label="$t('grading.student_final_outcome')">
            <div class="con-tab-ejemplo">
              <final-outcome />
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
      <h6
        v-else-if="!loadingCard && !isRoundActive"
        class="text-danger text-center">
        <span> {{ $t('grading.no_active_round_is_available_yet') }}</span>
      </h6>
    </div>
    <template v-slot:footer>
      <vs-button class="my-2 block mx-auto" type="line" @click="goToGradeBook"
        >{{ $t('grading.back_to_gradebook') }}
      </vs-button>
    </template>
  </vx-card>
</template>
<script>
// mixins
// import {commonDataMixin} from '@/mixins.js'
import gradingMixins from '@/modules/grading/grading.mixins'

// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook'),
  GradingRoundRepo = RepositoryFactory.get('GradingModule', 'rounds'),
  AllocationsRepo = RepositoryFactory.get('GradingModule', 'allocations'),
  ExamRepo = RepositoryFactory.get('ExamModule', 'Exam'),
  ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard'),
  ClassesRepository = RepositoryFactory.get('AcademicModule', 'Classes')

// Components
import assessmentsAndGrades from './components/assessmentsAndGrades'
import finalOutcome from './components/finalOutcome.vue'
import specialUpdates from './components/specialUpdates.vue'
import commentsAndFeedback from './components/commentsAndFeedback.vue'

export default {
  name: 'update-student-card',
  mixins: [gradingMixins],
  components: {
    assessmentsAndGrades,
    finalOutcome,
    specialUpdates,
    commentsAndFeedback
  },
  props: ['data'],

  data: () => ({
    //////////////////////
    //loading
    /////////////////////
    loadingCard: false,
    loadingSubjects: false,
    reloadData: false,
    showTabs: false,
    loadingStudents: false,
    //////////////////////
    // Data
    /////////////////////
    allocationSubjects: [],

    /////////////////////
    // Round
    /////////////////////
    isRoundActive: false,
    finishGettingAllocationSubjects: false
  }),
  computed: {
    studentId() {
      return parseInt(
        this.selectedStudent?.student_id ||
          this.selectedStudent?.studentId ||
          Number(this.$route.params.studentId)
      )
    },
    studentUserId() {
      return parseInt(
        this.selectedStudent?.user_id ||
          this.selectedStudent?.userId ||
          Number(this.$route.params.userId)
      )
    },
    subjectIdRouteParam() {
      return Number(this.$route.params.subjectId)
    },
    subjectId() {
      return parseInt(this.selectedSubjectId || this.subjectIdRouteParam)
    },
    subjectName() {
      return this.$route.query.subjectName
    },

    studentName() {
      return this.selectedStudent?.name || this.$route.query.studentName
    },
    className() {
      return this.selectedClass?.name || this.$route.query.className
    },
    divisionId() {
      return parseInt(
        this.selectedClass?.division_id || Number(this.$route.params.divisionId)
      )
    },
    sectionId() {
      return parseInt(
        this.selectedClass?.section_id || Number(this.$route.params.sectionId)
      )
    },
    gradeId() {
      return parseInt(
        this.selectedClass?.grade_id || Number(this.$route.params.gradeId)
      )
    },
    activeRoundRestDays() {
      return this.daysBetweenDates(
        new Date(this.activeRound.closing_date),
        new Date()
      )
    },
    showSpecialUpdates() {
      if (!this.activeRoundGradingId) return false
      const round = this.activeRound
      return (
        round.include_ATL_in_the_report_card ||
        round.include_CAS_TOK_EE_in_the_report_card ||
        round.include_IDU_in_the_report_card ||
        round.include_the_participation_in_the_report_card ||
        round.include_POI_in_the_report_card
      )
    }
  },
  methods: {
    ///////////////////////
    // Get Data APIs
    ///////////////////////
    async checkRoundOfClass() {
      // ---- Abort
      if (!this.classId) return
      this.loadingCard = true
      this.resetBeforeCheckRoundOfTheClass()
      return await GradingRoundRepo.checkRoundOfClass(
        this.classId,
        this.subjectId
      )
        .then((res) => {
          this.isRoundActive = Boolean(
            res.status !== 'error' && res.data !== 'not exist'
          )
          this.activeRound = this.isRoundActive ? res.data : {}
          this.selectedClass.grade_id =
            this.activeRound?.grading_management[0]?.grade_id || this.gradeId
          this.isStrandApproach = Boolean(res.data.is_strand)
          if (this.isStrandApproach) this.getCriterias()
          this.getAllocationSubjects()
          this.getReportCardDataOfSubject()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCard = false
        })
    },

    getAllocationSubjects() {
      if (this.finishGettingAllocationSubjects) return
      this.loadingSubjects = true

      AllocationsRepo.getClassAllocationSubjects(
        this.classId,
        this.activeRound.grading_allocation_id
      )
        .then((res) => {
          if (!this.allocationSubjects.length)
            this.allocationSubjects = [
              {id: this.subjectIdRouteParam, name: this.subjectName}
            ]
          const filterSubjects = (arr) =>
            arr.filter((s) => s.id !== this.subjectIdRouteParam)
          if (Array.isArray(res)) {
            this.allocationSubjects.push(...filterSubjects(res))
          } else {
            this.allocationSubjects.push(...filterSubjects(Object.values(res)))
          }
          this.finishGettingAllocationSubjects = true
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingSubjects = false
        })
    },
    // cirateria
    async getCriterias() {
      if (!this.selectedClass.grade_id) {
        this.errMsg(this.$t('form.the_grade_id_is_missed'))
        return
      }
      if (this.isDPSection || this.isAmerican) {
        this.cirterias = this.handleCriteria()
        return
      }

      // --- Continue
      const payload = {
        gradeId: this.gradeId,
        subjectId: this.subjectId,
        classIds: [this.classId]
      }
      ExamRepo.getCriterias(payload)
        .then((res) => {
          this.criterias = this.handleCriteria(res.data)
        })
        .catch(this.errMsg)
    },
    async getReportCardDataOfSubject() {
      this.loadingCard = true
      ReportCardRepo.getReportCardDataOfSubject(
        this.studentUserId,
        this.activeRoundGradingId,
        this.subjectId
      )
        .then(async (res) => {
          await (this.reportCardData = res.data)
          if (this.reportCardData.id) this.isReportCardCreated = true
        })
        .catch((err) => {
          this.resetReportCardData()
        })
        .finally(() => {
          this.loadingCard = false
          this.showTabs = true
        })
    },
    getClassStudents() {
      this.loadingStudents = true
      ClassesRepository.studentInClass(
        this.selectedClass.id,
        this.gradeId,
        this.divisionId
      )
        .then((res) => {
          res.data.forEach((s) => {
            s.name = `${s.first_name} ${s.middle_name} ${s.last_name}`
          })
          this.studentsOfSelectedClass = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingStudents = false
        })
    },
    ///////////////////////
    // on Change
    ///////////////////////
    refreshData() {
      this.showTabs = false
      this.resetReportCardData()
      // this.checkRoundOfClass()
      this.getReportCardDataOfSubject()
    },
    changeStudent(v) {
      this.$store.commit('setSelectedStudent', {
        name: v.name,
        id: v.id,
        userId: v.user_id,
        classId: this.classId,
        className: this.className,
        divisionId: this.divisionId,
        sectionId: this.sectionId
      })
      // this.$route.params.studentId = v.student_id
      // this.$route.params.userId = v.user_id
      // this.$route.query.studentName = v.name
      this.refreshData()
    },
    async changeSubject(v) {
      await this.$store.commit('setSelectedSubjectId', v)
      // this.$route.params.subjectId = v
      // this.$route.query.subjectName = this.selectedSubject.name
      this.refreshData()
    },

    ///////////////////////
    // Handle Data
    ///////////////////////
    handleMainData() {
      if (!this.selectedSubjectId) {
        this.selectedSubjectId = this.subjectId
        this.allocationSubjects = [{id: this.subjectId, name: this.subjectName}]
        this.selectedSubject = {id: this.subjectId, name: this.subjectName}
      }

      if (!this.activeRound.id) this.checkRoundOfClass()
      else {
        this.isRoundActive = true
        this.getAllocationSubjects()

        this.getReportCardDataOfSubject()
      }

      this.$store.commit('setSelectedStudent', {
        ...this.selectedStudent,
        name: this.studentName,
        id: this.studentId,
        userId: this.studentUserId,
        classId: this.classId,
        className: this.className,
        divisionId: this.divisionId,
        sectionId: this.sectionId
      })
      if (!this.selectedClass.division_id) {
        this.$store.commit('gradingSelectClass', {
          id: this.classId,
          division_id: this.divisionId,
          section_id: this.sectionId,
          grade_id: this.gradeId,
          name: this.className
        })
      }
      if (!this.studentsOfSelectedClass.length) this.getClassStudents()

      if (this.isMYPSection || this.isStrandApproach) this.getCriterias()
    },
    handleNoMainData() {},
    ///////////////////////
    // Reset Data
    ///////////////////////
    resetAllData() {},
    resetAllSelectedData() {
      this.selectedStudent = {}
      this.criterias = []
      this.criteriaScores = {}
      this.isReportCardCreated = false
      this.isStrandApproach = false
      this.subjectStrands = []
    },
    resetBeforeCheckRoundOfTheClass() {
      this.isRoundActive = false
      this.isStrandApproach = false
    },
    resetReportCardData() {
      this.reportCardData = {}
      this.isReportCardCreated = false
    }
    ///////////////////////
    // Reload tabs
    ///////////////////////
    // reloadAssessmentsTab() {}
  },
  mounted() {
    this.handleMainData()
  },
  beforeDestroy() {
    this.resetAllSelectedData()
  }
}
</script>

<style>
#update-card-tabs .con-tab-ejemplo {
  min-height: 30vh;
}
</style>
