<template>
  <div>
    <h5 class="text-center mb-3">
      {{ $t('grading.update_the_CAS_TOK_EE') }}
    </h5>
    <form class="lg:w-2/3 w-full mx-auto my-10" @submit="submitData">
      <template>
        <h5 class="mb-10">{{ $t('grading.CAS') }}</h5>
        <div class="mb-5 w-full flex md:flex-row flex-col items-center">
          <label class="md:w-1/4 w-full">{{
            $t('grading.CAS_progress')
          }}</label>
          <div class="md:w-3/4 w-full">
            <v-select
              :options="CASOverallOptions"
              v-model="data.cas_progress_id"
              label="name"
              :reduce="(c) => c.id"
              class="w-full"
              :clearable="false"
              v-validate="'required'"
              name="cas_progress"
              :showLoadingIcon="loadingCASOverallOptions" />
            <span class="text-danger text-sm block w-full">{{
              errors.first('cas_progress') | text_formatter
            }}</span>
          </div>
        </div>

        <div class="w-full flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{
            $t('grading.overall_progress')
          }}</label>
          <div class="md:w-3/4 w-full">
            <v-select
              :options="CASOverallOptions"
              v-model="data.cas_overall_progress_id"
              label="name"
              :reduce="(c) => c.id"
              class="w-full"
              :clearable="false"
              v-validate="'required'"
              name="cas_overall_progress"
              :showLoadingIcon="loadingCASOverallOptions" />
            <span class="text-danger text-sm">{{
              errors.first('cas_overall_progress') | text_formatter
            }}</span>
          </div>
        </div>

        <div class="w-full flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{
            $t('grading.planned_outcomes')
          }}</label>
          <div class="md:w-3/4 w-full">
            <v-select
              :options="CASPlannedOptions"
              v-model="data.cas_planned_outcome_id"
              label="name"
              :reduce="(c) => c.id"
              class="w-full"
              :clearable="false"
              v-validate="'required'"
              name="cas_planned_outcome"
              :showLoadingIcon="loadingCASPlannedOptions" />
            <span class="text-danger text-sm">{{
              errors.first('cas_planned_outcome') | text_formatter
            }}</span>
          </div>
        </div>
        <div class="w-full flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{
            $t('grading.completed_outcomes')
          }}</label>
          <div class="md:w-3/4 w-full">
            <v-select
              :options="CASPlannedOptions"
              v-model="data.cas_completed_outcome_id"
              label="name"
              :reduce="(c) => c.id"
              class="w-full"
              :clearable="false"
              v-validate="'required'"
              name="cas_completed_outcome"
              :showLoadingIcon="loadingCASPlannedOptions" />
            <span class="text-danger text-sm">{{
              errors.first('cas_completed_outcome') | text_formatter
            }}</span>
          </div>
        </div>
        <div class="w-full flex md:flex-row flex-col items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.hours') }}</label>
          <div class="flex w-full md:w-3/4">
            <div class="mb-2 w-full">
              <vs-input
                v-model="data.cas_creativity_hour"
                class="w-full"
                v-validate="'required|numeric|max_value:100|min_value:1'"
                name="cas_creativity_hour"
                max="100"
                min="1"
                type="number"
                :placeholder="$t('grading.creativity')" />

              <span class="text-danger text-sm">{{
                errors.first('cas_creativity_hour') | text_formatter
              }}</span>
            </div>
            <div class="mb-2 w-full mx-2">
              <vs-input
                v-model="data.cas_activity_hour"
                class="w-full"
                v-validate="'required|numeric|max_value:100|min_value:1'"
                name="cas_activity_hour"
                max="100"
                min="1"
                type="number"
                :danger="
                  !validData &&
                  (!Boolean(data.cas_activity_hour) ||
                    isNaN(data.cas_activity_hour))
                "
                :placeholder="$t('grading.activity')" />

              <span class="text-danger text-sm">{{
                errors.first('cas_activity_hour') | text_formatter
              }}</span>
            </div>
            <div class="mb-2 w-full">
              <vs-input
                v-model="data.cas_service_hour"
                class="w-full"
                type="number"
                v-validate="'required|numeric|max_value:100|min_value:1'"
                name="cas_service_hour"
                max="100"
                min="1"
                :danger="
                  !validData &&
                  (!Boolean(data.cas_service_hour) ||
                    isNaN(data.cas_service_hour))
                "
                :placeholder="$t('grading.service')" />

              <span class="text-danger text-sm">{{
                errors.first('cas_service_hour') | text_formatter
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.comment') }}</label>
          <div class="md:w-3/4 w-full">
            <vs-input
              v-model="data.cas_comment"
              class="w-full"
              v-validate="'required'"
              name="cas_comment" />
            <span class="text-danger text-sm">{{
              errors.first('cas_comment') | text_formatter
            }}</span>
          </div>
        </div>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{
            $t('grading.CAS_coordinator')
          }}</label>
          <div class="w-full md:w-3/4">
            <vs-input
              v-model="data.cas_coordinator"
              class="w-full"
              v-validate="'required'"
              name="cas_coordinator" />
            <span class="text-danger text-sm">{{
              errors.first('cas_coordinator') | text_formatter
            }}</span>
          </div>
        </div>
      </template>
      <vs-divider class="w-full" />
      <template>
        <h5 class="mb-5">{{ $t('grading.extended_essay') }}</h5>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.title') }}</label>
          <div class="w-full md:w-3/4">
            <vs-input
              v-model="data.extended_essay_title"
              class="w-full"
              v-validate="'required'"
              name="extended_essay_title" />
            <span class="text-danger text-sm">{{
              errors.first('extended_essay_title') | text_formatter
            }}</span>
          </div>
        </div>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.supervisor') }}</label>
          <div class="w-full md:w-3/4">
            <vs-input
              v-model="data.extended_essay_supervisor"
              class="w-full"
              v-validate="'required'"
              name="extended_essay_supervisor" />
            <span class="text-danger text-sm">{{
              errors.first('extended_essay_supervisor') | text_formatter
            }}</span>
          </div>
        </div>
      </template>
      <vs-divider class="w-full" />
      <template>
        <h5 class="mb-5">{{ $t('grading.toK_paper') }}</h5>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.title') }}</label>
          <div class="w-full md:w-3/4">
            <vs-input
              v-model="data.tok_paper_title"
              class="w-full"
              v-validate="'required'"
              name="tok_paper_title" />
            <span class="text-danger text-sm">{{
              errors.first('tok_paper_title') | text_formatter
            }}</span>
          </div>
        </div>
        <div class="flex md:flex-row flex-col mb-5 items-center">
          <label class="md:w-1/4 w-full">{{ $t('grading.title') }}</label>
          <div class="w-full md:w-3/4">
            <vs-input
              v-model="data.tok_paper_supervisor"
              class="w-full"
              v-validate="'required'"
              name="tok_paper_supervisor" />
            <span class="text-danger text-sm">{{
              errors.first('tok_paper_supervisor') | text_formatter
            }}</span>
          </div>
        </div>
      </template>
    </form>
    <vs-button
      class="vs-con-loading__container mt-10 mx-auto block"
      @click="submitData"
      ref="submitLoader"
      id="submitLoader"
      :disabled="loadSubmit"
      >{{ $t('grading.click_to_save_after_teacher_review') }}
    </vs-button>
  </div>
</template>
<script>
// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name: 'update-cas-form',
  mixins: [gradingMixins],

  data() {
    return {
      /////////////////////////
      // Data
      /////////////////////////
      CASOverallOptions: [],
      CASPlannedOptions: [],

      /////////////////////////
      // Loading
      /////////////////////////
      loadingCASOverallOptions: false,
      loadingCASPlannedOptions: false,
      ////////////////////////
      // Updates
      ////////////////////////
      data: {}
    }
  },
  computed: {
    validData() {
      return !this.errors.any()
    }
    // data:{
    //   get () {
    //     return this.reportCardData.cas_tok_ee || window._.cloneDeep(
    //       {

    //         'cas_progress_id':null,
    //         'cas_overall_progress_id':null,
    //         'cas_planned_outcome_id':null,
    //         'cas_completed_outcome_id':null,
    //         'cas_creativity_hour':null,
    //         'cas_activity_hour':null,
    //         'cas_service_hour':null,
    //         'cas_comment':null,
    //         'cas_coordinator':null,
    //         'extended_essay_title':null,
    //         'extended_essay_supervisor':null,
    //         'tok_paper_title':null,
    //         'tok_paper_supervisor':null

    //       })
    //   },
    //   set (v) {
    //     alert('hiii')
    //     this.reportCardData = {
    //       ... window._.cloneDeep(this.reportCardData),
    //       cas_tok_ee:v }
    //   }
    // }
  },
  watch: {
    data(v) {
      this.reportCardData = {
        ...this.reportCardData,
        cas_tok_ee: v
      }
    }
  },
  methods: {
    /////////////////////
    // APIs
    ////////////////////
    getCASOverallOptions() {
      this.loadingCASOverallOptions = true
      ReportCardRepo.getCASOverallOptions()
        .then((res) => {
          this.CASOverallOptions = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCASOverallOptions = false
        })
    },
    getCASPlannedOptions() {
      this.loadingCASOverallOptions = true

      ReportCardRepo.getCASPlannedOptions()
        .then((res) => {
          this.CASPlannedOptions = res.data
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCASOverallOptions = false
        })
    },
    saveData() {
      this.startLoadingSubmit()
      const payload = {
        user_id: this.selectedStudentUserId,
        round_id: this.activeRoundGradingId,
        ...this.data
      }
      ReportCardRepo.saveCASUpdates(payload)
        .then(() => this.successMsg(this.$t('form.saved_successfully')))
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    },
    submitData() {
      // this.$validator.validateAll().then((valid) => {
      if (this.validData) {
        this.saveData()
      } else {
        this.errMsg()
      }
      // })
    }
  },
  created() {
    this.getCASOverallOptions()
    this.getCASPlannedOptions()
    if (!this.reportCardData.cas_tok_ee)
      this.data = {
        cas_progress_id: null,
        cas_overall_progress_id: null,
        cas_planned_outcome_id: null,
        cas_completed_outcome_id: null,
        cas_creativity_hour: null,
        cas_activity_hour: null,
        cas_service_hour: null,
        cas_comment: null,
        cas_coordinator: null,
        extended_essay_title: null,
        extended_essay_supervisor: null,
        tok_paper_title: null,
        tok_paper_supervisor: null
      }
    else this.data = this.reportCardData.cas_tok_ee
  }
}
</script>
<style lang=""></style>
