<template>
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>

    <!--    Actions -->
    <div class="flex justify-between mt-10" v-if="this.isReportCardCreated">
      <div class="mx-auto" v-if="isCoordinator && !isStrandApproach">
        <vs-button
          :type="showForAllClass ? 'border' : 'flat'"
          class="mr-2"
          @click="showStudents(true)">
          {{ $t('grading.for_all_students') }}
        </vs-button>
        <vs-button
          :type="showForAllClass ? 'flat' : 'border'"
          @click="showStudents(false)">
          {{ $t('grading.for_all_subjects') }}
        </vs-button>
      </div>
    </div>
    <div class="my-5">
      <vs-button
        type="line"
        class="block my-3 ml-auto mr-0"
        @click="exportCSV"
        v-if="data.length">
        <span class="flex items-center">
          <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
          <span>CSV</span>
        </span>
      </vs-button>
      <!--    Strands Table -->
      <template v-if="isStrandApproach">
        <vs-table
          class="m-auto gradebook-table-style"
          :data="data"
          :hoverFlat="true"
          :noDataText="noDataText"
          :class="{'no-data': !data.length}"
          id="student-score-table">
          <!-- Header -->
          <template slot="thead" v-if="data.length">
            <vs-th
              v-for="(tr, indextr) in headers"
              :key="indextr"
              :sort-key="tr.field"
              class="table__header w-1/4 text-center"
              :class="{'w-1/2': tr.field === 'description'}">
              {{ tr.name }}
            </vs-th>
          </template>
          <!-- Rows -->
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
              <template v-for="(row_data, field, indx) in tr">
                <vs-td v-if="field === 'description'" :key="indx" class="w-1/2">
                  <span class="px-3"> {{ row_data }}</span>
                </vs-td>
                <vs-td v-if="field === 'assement'" class="text-center w-1/4">
                  {{ row_data }}
                </vs-td>
                <vs-td
                  v-if="field === 'other_assement'"
                  class="w-1/4"
                  :class="{'text-center': !row_data}">
                  {{ row_data || '-' }}
                </vs-td>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </template>

      <!-- Resources Table -->
      <vs-table
        v-else
        class="m-auto gradebook-table-style"
        :data="data"
        :hoverFlat="true"
        :noDataText="noDataText"
        :class="{'no-data': !data.length}">
        <!-- Header -->
        <template slot="thead" v-if="data.length">
          <vs-th></vs-th>
          <vs-th
            v-for="(tr, indextr) in headers"
            :key="indextr"
            :sort-key="tr.field"
            class="table__header"
            :class="{
              'criteria-cell':
                tr.field === 'criteriaScores' &&
                tr.field === 'totalScore' &&
                isMYPSection
            }"
            v-if="
              !isMYPSection
                ? tr.field !== 'criteriaScores' && tr.field !== 'totalScore'
                : true
            ">
            <div
              class="w-full"
              :class="{
                'flex flex-col':
                  tr.field === 'criteriaScores' &&
                  tr.field === 'totalScore' &&
                  isMYPSection
              }">
              <span class="block w-full text-center"
                >{{ tr.name | text_formatter }}
              </span>
              <span
                class="mt-2 flex justify-between w-full"
                v-if="
                  tr.field === 'criteriaScores' &&
                  tr.field === 'totalScore' &&
                  isMYPSection
                ">
                <span v-for="(c, i) in criterias" :key="'c_' + i">{{
                  c.key.toUpperCase()
                }}</span>
              </span>
            </div>
          </vs-th>
        </template>
        <!-- Rows -->
        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :data="tr"
            :key="indextr"
            class="text-center">
            <vs-td> {{ indextr + 1 }} </vs-td>
            <template v-for="(row_data, field, indx) in tr">
              <vs-td
                v-if="
                  !isMYPSection
                    ? field !== 'criteriaScores' && field !== 'totalScore'
                    : true
                "
                :key="indx"
                class="td-data"
                :class="{
                  'criteria-cell': field === 'criteriaScores' && isMYPSection
                }">
                <template v-if="field === 'criteriaScores'">
                  <span class="flex justify-around w-full">
                    <span
                      v-for="(c, i) in criterias"
                      :key="'k_' + i"
                      class="criteria-key"
                      >{{ row_data[c.key] || '' }}
                    </span>
                  </span>
                </template>
                <span v-else>{{ row_data }} </span>
              </vs-td>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <!-- Btns -->
    <template v-if="isCoordinator">
      <vs-button
        class="vs-con-loading__container my-2 block mx-auto"
        ref="submitLoader"
        id="submitLoader"
        :disabled="!data.length || !this.isReportCardCreated"
        @click="goToReportCard"
        >{{ $t('grading.click_to_Create_the_report_card') }}
      </vs-button>
      <p class="text-sm my-2 text-center">
        {{
          $t(
            'grading.the_created_report_cards_will_automatically_be_saved_inside_the_inventory'
          ) | text_formatter
        }}
      </p>
    </template>
  </div>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

export default {
  name: 'final-outcome',
  mixins: [gradingMixins],
  data() {
    return {
      headers: [],
      finalOutcomes: [],
      data: [],
      noBoundaryLocalGrade: false,
      showForAllClass: false
    }
  },
  computed: {
    // data () {
    //   const limits = this.selectedBoundary.limits[0]
    //   return [
    //     { // subjectName:this.selec,
    //       criteriaScores:this.criteriaScores.averagesTotalArr,
    //       totalScore:this.criteriaScores.averagesTotal,
    //       totalScorePercentage:`${this.criteriaScores.averagesPercentage } %`,
    //       localGrade:limits.local_grade,
    //       boundaries:`${limits.min}% - ${limits.max}%`
    //     }
    //   ]
    // }
    noDataText() {
      return !this.loadDialog
        ? this.text_formatter(
            this.$t('grading.save_assessments_and_grades_first')
          )
        : ''
    },
    hasStrands() {
      return Boolean(this.reportCardData.strands?.length)
    }
  },
  methods: {
    //////////////////////
    // APIs
    //////////////////////
    getAllFinalOutcomesForStudent() {
      this.startLoadingDialog()
      ReportCardRepo.getStudentAllFinalOutcome(
        this.selectedStudentUserId,
        this.activeRoundGradingId
      )
        .then((res) => {
          this.finalOutcomes = res.data
          this.handleTableData(res.data.subjects_data).then(this.setCachedData)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    getClassFinalOutcomesofSubject() {
      this.startLoadingDialog()
      ReportCardRepo.getClassFinalOutcomesofSubject(
        this.selectedClass.id,
        this.activeRoundGradingId,
        this.selectedSubjectId
      )
        .then((res) => {
          this.finalOutcomes = res.data
          this.handleTableData(res.data).then(this.setCachedData)
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    ///////////////////////
    // handle data
    ////////////////////////
    handleTableData(data) {
      return new Promise((resolve, reject) => {
        this.noBoundaryLocalGrade = data.some(
          (e) => e.boundary_limits?.local_grade === null
        )

        this.data = data.map((e) => {
          const noData = '-'
          const commeProps = {
            criteriaScores: this.isMYPSection ? e.criteria : null,
            totalScore: e.total_score || noData,
            totalScorePercentage:
              Math.round(e.total_score_percentage) || noData,
            grade: e.boundary_limits ? e.boundary_limits.grade : noData,
            boundaries: e.boundary_limits
              ? `${Math.round(e.boundary_limits.min)}% - ${Math.round(
                  e.boundary_limits.max
                )}%`
              : noData
          }

          let obj = {}
          if (this.showForAllClass) obj.studentName = e.student_name
          else obj.subjectName = e.subject_name

          obj = {
            ...obj,
            ...commeProps
          }

          if (this.isAmerican) {
            obj['weight'] = e.allocation?.weight
              ? `${e.allocation?.weight} CD`
              : '-'
          }
          if (this.noBoundaryLocalGrade) return obj
          else {
            return {
              ...obj,
              localGrade: e.boundary_limits
                ? e.boundary_limits.local_grade
                : noData
            }
          }
        })
        if (this.isAmerican) {
          this.headers.push({
            name: this.$t('grading.Credits'),
            field: 'weight'
          })
        }
        if (!this.noBoundaryLocalGrade) {
          this.headers.push({
            name: this.$t('grading.local_grade'),
            field: 'localGrade'
          })
        }

        resolve(true)
      })
    },
    handleRefreshData() {
      this.resetCachedData()
      const commonCols = [
          {
            name: this.$t('grading.percentage'),
            field: 'totalScorePercentage'
          },
          {
            name: this.$t('grading.grade'),
            field: 'grade'
          },

          {
            name: this.$t('grading.boundaries'),
            field: 'boundaries'
          }
        ],
        MYPHeaderCols = [
          {
            name: `${this.$t('grading.achievements')} ${this.$t(
              'grading.breakdown'
            )}`,
            field: 'criteriaScores'
          },
          {name: this.$t('grading.achievements'), field: 'totalScore'}
        ]

      if (this.showForAllClass) {
        this.headers.push({
          name: this.$t('basic.subject'),
          field: 'studentName'
        })
      } else {
        this.headers.push({
          name: this.$t('basic.subject'),
          field: 'subjectName'
        })
      }

      if (this.isMYPSection) this.headers.push(...MYPHeaderCols)
      this.headers.push(...commonCols)

      if (this.showForAllClass) this.getClassFinalOutcomesofSubject()
      else this.getAllFinalOutcomesForStudent()
    },
    //////////////////////
    // GoTo
    //////////////////////
    goToReportCard() {
      this.$router.push({
        name: 'GradingReportCard',
        params: {
          studentId: this.selectedStudentUserId,
          gradingRoundId: this.activeRoundGradingId
        },
        query: {
          studentName: this.selectedStudent.name
        }
      })
    },
    ///////////////////////
    // Check Data
    ///////////////////////
    checkCacheReportData() {
      if (!this.isUpdateReportCard) {
        if (this.reportCardData?.finalOutcomeData?.data.length) {
          this.data = this.reportCardData.finalOutcomeData.data
          this.headers = this.reportCardData.finalOutcomeData.headers
          this.showForAllClass =
            this.reportCardData.finalOutcomeData.showForAllClass
        } else if (this.isStrandApproach) {
          this.headers = [
            {name: this.$t('subject.strand'), field: 'description'},
            {name: this.$t('grading.assessments'), field: 'assement'},
            {
              name: this.$t('grading.notes'),
              field: 'other_assement'
            }
          ]
          this.data = this.handleStrandsSubjectData(this.reportCardData.strands)
        } else this.handleRefreshData()
      } else this.handleRefreshData()
    },
    resetCachedData() {
      this.reportCardData.finalOutcomeData = {}
      this.headers = []
      this.data = []
    },
    setCachedData() {
      this.reportCardData.finalOutcomeData = {
        data: this.data,
        headers: this.headers,
        showForAllClass: this.showForAllClass
      }
      this.isUpdateReportCard = false
    },
    ///////////////////////
    // Export
    ///////////////////////
    // TO CSV
    exportCSV() {
      this.successMsg(this.$t('form.Started_Downloading'))
      const dataRows = window._.cloneDeep(this.data),
        headerCols = window._.cloneDeep(this.headers)
      if (this.isMYPSection) {
        dataRows.forEach((e) => {
          e.criteriaScores = Object.values(e.criteriaScores).join('-')
        })
        headerCols.some((e) => {
          if (e.field === 'criteriaScores') {
            e.name = `${e.name}  (${this.criteriaScores.keysHaveScores
              .join(' - ')
              .toUpperCase()})`
            return true
          }
        })
      }
      this.exportToExcel(
        headerCols,
        dataRows,
        ` ${!this.showForAllClass ? `${this.selectedStudent.name} - ` : ''}${
          this.selectedClass?.name
        } - ${this.$t('grading.final_outcome')}`
      )
    },
    ///////////////////////
    //
    ///////////////////////
    showStudents(show = true) {
      this.showForAllClass = show
      this.handleRefreshData()
    }
  },
  created() {
    if (this.isReportCardCreated) this.checkCacheReportData()
  }
}
</script>
<style lang="scss">
@import '@/modules/grading/components/gradeBookTable/gradingTable.scss';
</style>
