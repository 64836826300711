<template>
  <div>
    <h5 class="text-center mb-3">
      {{ $t('grading.update_IDU_SAA') }}
    </h5>
    <div class="my-10">
      <div class="mb-10" v-for="(u, i) in updates" :key="'u_' + i">
        <div class="w-full">
          <vs-row vs-align="center" vs-type="flex">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              :vs-lg="isMYPSection ? '5' : '7'"
              vs-sm="12"
              vs-xs="12">
              <div class="w-full">
                <h6 class="text-primary mb-5 md:text-left text-center">
                  {{ u.type }}
                </h6>
                <vs-input
                  v-model="u.name"
                  :placeholder="$t('grading.type_the_teacher_name')"
                  v-validate="'required'"
                  class="mb-4"
                  :danger="!validData && !Boolean(u.name)"
                  :name="`${u.type}_teacher_name`" />
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="5"
              vs-sm="7"
              vs-xs="12"
              class="text-center"
              v-if="isMYPSection">
              <div class="w-full mx-4">
                <h6 class="text-primary mb-0 mb1">
                  {{ $t('grading.achievements_levels') }}
                </h6>
                <div
                  class="flex md:justify-between justify-center items-center mb-5">
                  <div
                    v-for="(c, i) in criterias"
                    :key="`c_${i}`"
                    class="w-full mx-1">
                    <label class="text-xs bolder">{{ c.key }}</label>
                    <v-select
                      :options="scores"
                      v-model="u[c.key]"
                      class="w-full"
                      :clearable="false"
                      v-validate="'required'" />
                  </div>
                </div>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
              vs-sm="4"
              vs-xs="12"
              class="text-center mb-3 ml-auto mr-0">
              <div class="w-full">
                <h6 class="text-primary mb-5 block">
                  {{ $t('grading.final_grade') }}
                </h6>
                <vs-input
                  v-model="u.final_grade"
                  v-validate="'required|numeric'"
                  :danger="
                    !validData &&
                    (!Boolean(u.final_grade) || isNaN(u.final_grade))
                  "
                  :name="`${u.type}_final_grade_${i}`" />
                <!-- <span class="text-danger text-sm">{{
                  errors.first(`${u.type}_final_grade`) | text_formatter
                }}</span> -->
              </div>
            </vs-col>
          </vs-row>
          <div class="">
            <vs-textarea
              v-model="u.comment"
              :placeholder="$t('grading.type_the_teacher_comment')"
              v-validate="'required'"
              height="200px"
              :danger="!validData && !Boolean(u.comment)"
              :name="`${u.type}_teacher_comment${i}`"
              class="mb-0" />
            <span class="text-danger text-sm">{{
              errors.first(`${u.type}_teacher_comment_${i}`) | text_formatter
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <vs-button
      class="vs-con-loading__container mt-10 mx-auto block"
      @click="submitData"
      ref="submitLoader"
      id="submitLoader"
      :disabled="loadSubmit || !validData"
      >{{ $t('grading.click_to_save_after_teacher_review') }}
    </vs-button>
  </div>
</template>
<script>
// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name: 'update-idu-form',
  mixins: [gradingMixins],

  data() {
    return {
      scores: [1, 2, 3, 4, 5, 6, 7, 8]
    }
  },
  computed: {
    updates: {
      get() {
        const idu = this.reportCardData.reportCaredIDU

        if (!idu || !idu.length) {
          return [
            {
              name: '',
              comment: '',
              type: 'IDU',

              final_grade: null
            },
            {
              name: '',
              comment: '',
              type: 'SAA',
              final_grade: null
            }
          ]
        } else {
          if (idu.some((e) => !e.type)) {
            idu[0].type = 'IDU'
            idu[1].type = 'SAA'
          }
          return idu
        }
      },
      set(v) {
        this.reportCardData.reportCaredIDU = v
      }
    },
    validData() {
      return !this.errors.any()
    }
  },
  methods: {
    /////////////////////
    // APIs
    ////////////////////
    saveData() {
      this.startLoadingSubmit()
      const payload = {
        user_id: this.selectedStudentUserId,
        round_id: this.activeRoundGradingId,
        // subject_id : this.selectedSubjectId,
        data: this.updates
      }
      ReportCardRepo.saveIDUUpdates(payload)
        .then(() => this.successMsg(this.$t('form.saved_successfully')))
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    },
    submitData() {
      // this.$validator.validateAll().then((valid) => {
      if (this.validData) {
        this.saveData()
      } else {
        this.errMsg()
      }
      // })
    }
  }
}
</script>
<style lang=""></style>
