<template>
  <div>
    <h5 class="text-center mb-3">
      {{ $t("grading.update_The_participation") }}
    </h5>
    <v-select
      :options="options"
      label="name"
      :reduce="(s) => s.id"
      v-model="participation_grade_id"
      class="md:w-1/2 my-10 mx-auto w-full"
      :clearable="false"
      :placeholder="$t('form.choose_grade')"
      :showLoadingIcon="loadingOptions"
    />
    <!-- guide  -->
    <div class="guide mt-20 mb-10">
      <div class="guide-title flex py-3 text-center">
        <h6 class="w-1/5 text-center text-primary">
          {{ $t("grading.grade") }}
        </h6>
        <h6 class="w-4/5 text-center text-primary">
          {{ $t("grading.descriptor") }}
        </h6>
      </div>
      <ul class="guide-details">
        <li v-for="(g, i) in options" :key="i">
          <h6 class="w-1/5 text-center bold">{{ g.name }}</h6>
          <p class="w-4/5">{{ g.descriptor }}</p>
        </li>
      </ul>
    </div>

    <!-- Actions -->
    <vs-button
      class="vs-con-loading__container mt-10 mx-auto block"
      @click="saveData"
      ref="submitLoader"
      id="submitLoader"
      :disabled="loadSubmit || loadingOptions || !participation_grade_id"
      >{{ $t("grading.click_to_save_after_teacher_review") }}
    </vs-button>
  </div>
</template>
<script>
// Repos
import {
  RepositoryFactory
} from '@/Repositories/RepositoryFactory'
const
  ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name:'update-participation-form',
  mixins:[gradingMixins],

  data () {
    return {
      options:[],
      loadingOptions:false
    }
  },
  computed:{
    participation_grade_id:{
      get () { return this.reportCardData.participation_grade_id },
      set (v) {
        this.reportCardData.participation_grade_id = v
      }
    }
  },
  methods: {
    /////////////////////
    // APIs
    ////////////////////
    saveData () {
      this.startLoadingSubmit()

      const payload = {
        user_id:this.selectedStudentUserId,
        round_id:this.activeRoundGradingId,
        // subject_id : this.selectedSubjectId,
        participation_grade_id :this.participation_grade_id
      }

      ReportCardRepo.saveParticipationUpdates(payload)
        .then(() => this.successMsg(this.$t('form.saved_successfully')))
        .catch(this.errMsg)
        .finally(this.endLoadingSubmit)
    },
    getParticipationOptions () {
      this.loadingOptions = true
      ReportCardRepo.getParticipationOptions()
        .then((res) => {
          this.options = res.data
        })
        .catch(this.errMsg)
        .finally(() => { this.loadingOptions = false })
    }
  },
  created () {
    this.getParticipationOptions()
  }
}
</script>

