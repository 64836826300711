<template>
  <!-- Update grades Popup -->
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>

    <!-- Resources Table -->
    <template v-if="isStrandApproach">
      <!-- Strands Table -->
      <vs-table
        class="m-auto my-10 gradebook-table-style"
        :data="data"
        :hoverFlat="true"
        :noDataText="
          !loadDialog
            ? $t(
                'grading.this_subject_hasnt_any_strands_yet_kindly_go_to_the_subjects_department_to_handle_it'
              )
            : ''
        "
        :class="{'no-data': !data.length}"
        id="student-score-table">
        <!-- Header -->
        <template slot="thead" v-if="data.length">
          <vs-th class="table__header w-1/2 text-center">{{
            $t('subject.strand')
          }}</vs-th>
          <vs-th class="table__header w-1/4 text-center">{{
            $t('grading.assessments')
          }}</vs-th>
          <vs-th class="table__header w-1/4 text-center">{{
            $t('grading.notes')
          }}</vs-th>
        </template>
        <!-- Rows -->
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
            <template v-for="(row_data, field, indx) in tr">
              <template v-if="field !== 'strand_id'">
                <vs-td v-if="field === 'description'" :key="indx" class="w-1/2">
                  <span class="px-3"> {{ row_data }}</span>
                </vs-td>
                <vs-td v-if="field === 'assement'" class="w-1/4">
                  <template v-if="isPYPSection">
                    <v-select
                      :options="criterias"
                      label="title"
                      :reduce="(c) => c.key"
                      v-model="tr.assement"
                      class="w-full"
                      :placeholder="$t('form.grade') | text_formatter"
                      v-validate="{required: !Boolean(tr.assement)}"
                      :name="`${tr.name}_assessment`"
                      :value="tr.assement" />
                  </template>
                  <template v-else-if="isAmerican">
                    <v-select
                      :options="criterias"
                      v-model="tr.assement"
                      class="w-full"
                      :placeholder="$t('form.grade') | text_formatter"
                      v-validate="{required: !Boolean(tr.assement)}"
                      :name="`${tr.name}_assessment`"
                      :value="tr.assement" />
                  </template>
                  <template v-else-if="isMYPSection">
                    <div
                      class="flex items-center"
                      :class="{'mb-3': criterias.length}"
                      v-for="(cr, i) in criterias"
                      :key="i">
                      <label class="w-1/5">{{ cr.key }}</label>
                      <vs-input
                        v-model="tr.assement[cr.key]"
                        type="number"
                        :max="8"
                        min="0"
                        class="w-4/5"
                        :placeholder="$t('form.grade') | text_formatter"
                        v-validate="'numeric|max_value:8|min_value:0'"
                        name="all_grades" />
                    </div>
                  </template>
                </vs-td>
                <vs-td v-if="field === 'other_assement'" class="w-1/4">
                  <vs-textarea
                    v-model="tr.other_assement"
                    class="w-full"
                    :name="`${tr.name}_otherAssess`"
                    :value="tr.other_assement" />
                </vs-td>
              </template>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </template>
    <template v-else>
      <vs-table
        class="m-auto my-10 gradebook-table-style"
        :data="data"
        :hoverFlat="true"
        :noDataText="!loadDialog ? $t('grading.no_resources_assessed') : ''"
        :class="{'no-data': !data.length}"
        id="student-score-table">
        <!-- Header -->
        <template slot="thead" v-if="data.length">
          <vs-th></vs-th>
          <vs-th
            v-for="(tr, indextr) in headers"
            :key="indextr"
            :sort-key="tr.field"
            class="table__header"
            :class="{
              'text-center': tr.field !== 'name',
              'criteria-cell': tr.field === 'grade' && isMYPSection
            }">
            <div
              class="w-full"
              :class="{
                'flex flex-col': tr.field === 'grade' && isMYPSection
              }">
              <span class="block w-full">{{ tr.title | text_formatter }} </span>
              <span
                class="flex justify-between w-full"
                v-if="tr.field === 'grade' && isMYPSection">
                <span
                  v-for="c in criteriasKeys"
                  :key="c"
                  class="criteria-key"
                  >{{ c.toUpperCase() }}</span
                >
              </span>
            </div>
          </vs-th>
        </template>
        <!-- Rows -->
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
            <template v-for="(row_data, field, indx) in tr">
              <vs-td
                v-if="
                  field !== 'id' &&
                  field !== 'typeFlag' &&
                  field !== 'originalShowInGrading' &&
                  field !== 'applying_allocation_points' &&
                  field !== 'grading_id' &&
                  field !== 'typeOriginal'
                "
                :key="indx"
                :class="{
                  'text-center': field !== 'name',
                  'not-available-cell': !row_data,
                  'not-available-cell': row_data === 'N-A',
                  'criteria-cell': field === 'grade' && isMYPSection
                }"
                class="td-data">
                <vs-checkbox
                  v-model="tr.showInGrading"
                  v-if="field === 'showInGrading'"
                  :vs-value="tr.showInGrading"
                  @change="changeGradeStatus(tr)"
                  :disabled="loadingChangeStatus || loadSubmit" />

                <span
                  class="flex w-full"
                  v-else-if="field === 'grade' && isMYPSection"
                  :class="row_data ? 'justify-between' : 'justify-center'">
                  <template v-if="row_data">
                    <span
                      v-for="c in criteriasKeys"
                      :key="'d_' + c"
                      class="criteria-key"
                      >{{ row_data[c] }}</span
                    >
                  </template>
                  <span v-else>N-A</span>
                </span>
                <span v-else class="text-center"> {{ row_data }}</span>
              </vs-td>
            </template>

            <vs-td v-if="showManualUpdate" class="td-data">
              <div class="">
                <template v-if="isPYPSection">
                  <v-select
                    :options="tr.criteria"
                    label="title"
                    :reduce="(c) => c.key"
                    v-model="tr.score"
                    class="w-full"
                    :placeholder="$t('form.grade') | text_formatter"
                    v-validate="{
                      required: !Boolean(tr.score)
                    }"
                    :name="`${tr.name}_grade`" />
                </template>
                <template v-else-if="isMYPSection">
                  <div
                    class="flex items-center"
                    :class="{'mb-3': tr.criteria.length}"
                    v-for="(cr, i) in tr.criteria"
                    :key="i">
                    <label class="w-1/5 mr-2">{{ cr.key }}</label>
                    <vs-input
                      v-model="tr.score[cr.key]"
                      type="number"
                      :max="8"
                      min="0"
                      class="w-4/5"
                      :placeholder="$t('form.grade') | text_formatter"
                      v-validate="{
                        required: true,
                        numeric: true,
                        max_value: 8,
                        min_value: 0
                      }"
                      :name="`${tr.name}_grade`" />
                  </div>
                </template>
                <template v-else-if="isDPSection || isAmerican">
                  <v-select
                    :options="tr.criteria"
                    v-model="tr.score"
                    class="w-full"
                    :placeholder="$t('form.grade') | text_formatter"
                    v-validate="{required: !Boolean(tr.score)}"
                    :name="`${tr.name}_grade`"
                    :value="tr.score"
                /></template>

                <span class="text-danger text-sm block w-full">{{
                  errors.first(`${tr.name}_grade`) | text_formatter
                }}</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </template>

    <!-- Btns -->
    <div
      class="flex items-center"
      :class="showCancelBtn ? 'justify-between' : 'justify-center'">
      <vs-button
        class="mt-2"
        type="border"
        color="danger"
        @click="closeUpdateStudentGrades"
        v-if="showCancelBtn"
        >{{ $t('basic.cancel') }}
      </vs-button>
      <vs-button
        class="vs-con-loading__container mt-2 mx-2"
        @click="submitData"
        v-if="data.length"
        ref="submitLoader"
        id="submitLoader"
        :disabled="loadSubmit || loadingChangeStatus"
        >{{ $t('grading.click_to_save_after_teacher_review') }}
      </vs-button>
    </div>
  </div>
</template>
<script>
// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const GradebookRepo = RepositoryFactory.get('GradingModule', 'gradebook'),
  BoundaryRepo = RepositoryFactory.get(
    'boundariesAndDescriptors',
    'boundariesAndDescriptors'
  ),
  ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard'),
  SubjectsRepo = RepositoryFactory.get('AcademicModule', 'Subjects')

import gradingMixins from '@/modules/grading/grading.mixins'

// plugins
import moment from 'moment'

export default {
  name: 'assessments-and-grades',
  props: {
    showCancelBtn: {
      type: Boolean,
      require: false,
      default: true
    },
    reloadData: {
      type: Boolean,
      require: false,
      default: false
    },
    showManualUpdate: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  mixins: [gradingMixins],
  data() {
    return {
      loadingChangeStatus: false,
      headers: [
        {
          field: 'name',
          title: this.$t('basic.name')
        },
        {
          field: 'type',
          title: this.$t('basic.type')
        },

        {
          field: 'date',
          title: this.$t('basic.date')
        },
        {
          field: 'grade',
          title: `${this.$t('form.grade')}`
        }
      ],
      data: [],
      allResourcesObj: {},
      /////////////////////////
      // Total scores
      /////////////////////////
      criteriaScoresLocal: {},
      setScoresByTypes: true
    }
  },
  computed: {
    criteriasKeys() {
      return (
        this.criteriaScoresLocal.keysHaveScores ||
        this.criterias.map((e) => e.key) ||
        []
      )
    },
    criteriasKeysString() {
      return this.criteriasKeys.join('-')
    },
    subjectId() {
      return this.selectedSubjectId || this.$route.params.subjectId
    }
  },
  watch: {},
  methods: {
    /////////////////////
    // APIs
    //////////////////////
    // Get student grades

    async getStudentGradingsByAllocation() {
      // --- Continue
      this.startLoadingDialog()

      await GradebookRepo.getStudentGradingsByAllocation(
        this.classId,
        this.selectedStudentId,
        this.activeRound.grading_allocation_id,
        {
          subject_id: this.subjectId,
          start_date: this.activeRound.start_date,
          end_date: this.activeRound.end_date
        }
      )
        .then((res) => {
          this.allData = res
          this.allResourcesObj = res
          delete this.allResourcesObj.students

          for (const key in res) {
            if (Array.isArray(res[key]) && res[key].length) {
              this.handleStudentData(res)
              return
            }
          }
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },

    getBoundaryByStudent() {
      // if (!this.activeRound.display_the_achieved_grade_beside_the_boundary) return
      if (
        isNaN(this.criteriaScoresLocal.averagesPercentage) ||
        this.criteriaScoresLocal.averagesPercentage === null
      )
        return
      const payload = {
        // total_score_percentage : this.isMYPSection ? this.criteriaScores.averagesTotal : this.criteriaScores.averagesPercentage
        total_score_percentage: this.isMYPSection
          ? this.criteriaScoresLocal.averagesTotal
          : this.criteriaScoresLocal.averagesPercentage
      }
      this.startLoadingDialog()
      BoundaryRepo.getBoundaryByStudent(
        this.selectedStudentUserId,
        this.selectedSubjectId,
        payload
      )
        .then((res) => {
          this.selectedBoundary = res
        })
        // .catch(err => this.errMsg(err, this.$t('grading.boundaries')))
        .finally(() => {
          this.endLoadingDialog()
          this.stopVSLoading()
        })
    },
    async changeGradeStatus(resource) {
      this.startLoadingDialog()
      this.loadingChangeStatus = true
      const payload = {
        resource_id: resource.grading_id,
        resource_type: 'grading',
        status: resource.showInGrading ? 'unhidden' : 'hidden'
      }
      GradebookRepo.changeGradeStatus(payload)
        .then((res) => {
          this.successMsg()
          const grade = this.isMYPSection
            ? resource.grade
            : Number(resource.applying_allocation_points)
          this.updateCriteriaScores(
            grade,
            resource.showInGrading,
            this.setScoresByTypes ? resource.typeOriginal : null
          )
          this.isReportCardCreated = false
          this.isUpdateReportCard = false
        })
        .catch((err) => {
          this.errMsg(err)
          resource.showInGrading = !resource.showInGrading
        })
        .finally(() => {
          this.loadingChangeStatus = false
          this.endLoadingDialog()
          this.stopVSLoading()
        })
    },
    getSubjectStrands() {
      if (this.reportCardData.strands?.length) {
        this.data = this.handleStrandsSubjectData(this.reportCardData.strands)
        // this.reportCardData.finalOutcomeData = {data: this.data}
        return
      }

      this.startLoadingDialog()
      this.subjectStrands = []
      this.data = []
      SubjectsRepo.getSubjectStrands(this.selectedSubjectId)
        .then(async (res) => {
          await (this.subjectStrands = res.data)
          if (this.subjectStrands.length) {
            await (this.data = this.handleStrandsSubjectData(
              this.subjectStrands
            ))
            this.reportCardData.assessmentsDataTable = this.data
          }
        })
        .catch(this.errMsg)
        .finally(() => {
          this.endLoadingDialog()
        })
    },

    ///////// Save ReportCard

    async createReportCard() {
      this.startLoadingSubmit()
      const criteria = {}
      if (this.isMYPSection) {
        for (const key in this.criteriaScores) {
          if (key.length === 1) criteria[key] = this.criteriaScores[key].average
        }
      }
      const payload = {
        user_id: this.selectedStudentUserId,
        grading_round_id: this.activeRound.id,
        subject_id: this.subjectId,
        boundary_id: this.selectedBoundary.id,
        total_score: this.criteriaScores.averagesTotal,
        total_score_percentage: this.criteriaScores.averagesPercentage,
        criteria: this.isMYPSection ? criteria : [] // for only myp defult []
      }

      ReportCardRepo.createReportCard(payload)
        .then((res) => {
          this.isReportCardCreated = true
          this.isUpdateReportCard = true
          this.successMsg()
          if (res.data) this.reportCardData = res.data
        })
        .catch((err) => {
          this.errMsg(err)
          this.isReportCardCreated = false
        })
        .finally(this.endLoadingSubmit)
    },

    async saveReportCardSubjectStrands() {
      const payload = {
        user_id: this.selectedStudentUserId,
        grading_round_id: this.activeRound.id,
        subject_id: this.subjectId,
        strands: this.data.map((e) => {
          // if (!e.assement.constructor.name === 'Object')
          e.assement = String(e.assement)
          return e
        })
      }
      this.startLoadingSubmit()

      return ReportCardRepo.saveReportCardSubjectStrands(payload)
        .then(() => {
          this.successMsg()
          this.isReportCardCreated = true
          this.reportCardData.strands = this.data
        })
        .catch((err) => {
          this.errMsg(err)
          this.isReportCardCreated = false
        })
        .finally(this.endLoadingSubmit)
    },
    ///////////////////////
    // Reset Data
    ///////////////////////
    resetStudentGradingData() {
      this.criteriaScores = {}
    },
    ///////////////////////
    // Handle Data
    ///////////////////////

    //  Resources
    async handleResourcesInfo(data) {
      const resources = [],
        commonProps = (e) => ({
          // subjectName:e.subject.name,
          // subjectId:e.subject.id,
          dateFormatted: new moment(e.date).format('D-MMM'),
          dateFormatted2: new moment(e.date).format('DD-MMMM-YYYY')
        })

      await this.initCriteriaScore()

      for (const key in data) {
        // set type name
        let typeName = '',
          typeFlag = ''
        if (key.includes('grading_type_detail')) {
          typeName = this.text_formatter(key.replace('grading_type_detail', ''))
          typeFlag = 'gradingTypeDetail'
        } else {
          typeName = this.text_formatter(key)
          typeFlag = key.includes('exam') ? 'exam' : key
        }

        // Customize Resource Object
        if (key !== 'students' && data[key].length > 0) {
          resources.push(
            ...data[key].map((e) => {
              const common = commonProps(e)
              e = Object.assign(common, e)
              e.type = typeName
              e.typeFlag = typeFlag
              e.typeOriginal = key

              if (!e.hide_from_grading_for_student) {
                if (this.isMYPSection) {
                  for (const ciraKey in e.original_criteria) {
                    const score = parseInt(e.original_criteria[ciraKey])
                    const criteriaKey = window._.cloneDeep(
                      this.criteriaScoresLocal[ciraKey]
                    )
                    criteriaKey.scores.push(score)
                    this.criteriaScoresLocal[ciraKey] =
                      window._.cloneDeep(criteriaKey)
                  }
                } else if (this.setScoresByTypes) {
                  this.criteriaScoresLocal.scores[key].push(
                    e.applying_allocation_points
                  )
                } else {
                  this.criteriaScoresLocal['scores'].push(
                    e.applying_allocation_points
                  )
                }
              }

              return e
            })
          )
        }
      }
      this.allResources = resources.sort((a, b) =>
        new moment(a.date).diff(b.date)
      )
      this.selectedStudent = {
        ...this.selectedStudent,
        allResources: this.allResources
      }
      this.calculateCriteriaScores()

      return this.allResources
    },
    async handleStudentData(data) {
      this.resetStudentGradingData()
      const resources = await this.handleResourcesInfo(data)
      this.data = resources.map((r) => {
        return {
          id: r.id,
          typeFlag: r.typeFlag,
          showInGrading: !r.hide_from_grading_for_student,
          name: r.name,
          type: r.type,
          date: r.dateFormatted,
          originalShowInGrading: !r.hide_from_grading_for_student,
          grade: this.isMYPSection
            ? r.original_criteria
            : r.original_criteria[0] ||
              r.score ||
              r.applying_allocation_points ||
              'N-A',
          applying_allocation_points: r.applying_allocation_points,
          grading_id: r.grading_id,
          typeOriginal: r.typeOriginal
        }
      })
      this.reportCardData.assessmentsDataTable = this.data
    },

    // /////////////////////
    // CriteriaScore
    ///////////////////////
    initCriteriaScore() {
      this.criteriaScoresLocal = {}
      // const obj = {scores:[], total:0, average:0}
      const scoresOfTypes = () => {
          const obj = {}
          for (const key in this.allResourcesObj) {
            const arr = this.allResourcesObj[key]
            obj[key] = []
          }
          return {scores: obj}
        },
        obj = {scores: []}

      if (this.isMYPSection) {
        this.criterias.forEach((e) => {
          this.criteriaScoresLocal[e.key] = obj
        })
      } else {
        this.criteriaScoresLocal = this.setScoresByTypes ? scoresOfTypes() : obj
      }
    },
    setMYPCirateriaScores(ciraKey, score) {
      if (ciraKey === 'a') this.aScores.push(score)
      else if (ciraKey === 'b') this.bScores.push(score)
      else if (ciraKey === 'c') this.cScores.push(score)
      else if (ciraKey === 'd') this.dScores.push(score)
    },
    async calculateCriteriaScores() {
      this.criteriaScores = {}

      const calculateTotal = (arr) =>
          arr.reduce((a, b) => Number(a) + Number(b), 0),
        calculateTotalAverage = (total, length) => Math.round(total / length)

      this.criteriaScoresLocal = {
        ...this.criteriaScoresLocal,
        averagesTotal: 0,
        averagesPercentage: 0,
        averagesTotalArr: []
      }
      if (this.isMYPSection) {
        await (this.criteriaScoresLocal.keysHaveScores = [])
        for (const key in this.criteriaScoresLocal) {
          if (key.length < 3) {
            const criteriaKey = window._.cloneDeep(
              this.criteriaScoresLocal[key]
            )

            if (criteriaKey.scores.length > 0) {
              await this.criteriaScoresLocal.keysHaveScores.push(key)
              criteriaKey.total = calculateTotal(criteriaKey.scores)
              criteriaKey.average = calculateTotalAverage(
                criteriaKey.total,
                criteriaKey.scores.length
              )
              this.criteriaScoresLocal[key] = window._.cloneDeep(criteriaKey)
              this.criteriaScoresLocal.averagesTotal += criteriaKey.average
              this.criteriaScoresLocal.averagesTotalArr.push(
                criteriaKey.average
              )
            }
          }
        }
        const CirateriaWeights =
          this.criteriaScoresLocal.keysHaveScores.length * 8
        this.criteriaScoresLocal.averagesPercentage = Math.round(
          (this.criteriaScoresLocal.averagesTotal / CirateriaWeights) * 100
        )
      } else if (this.setScoresByTypes) {
        const scores = this.criteriaScoresLocal.scores
        const total = []
        for (const key in scores) {
          const arr = scores[key]

          if (arr.length) {
            total.push(calculateTotalAverage(calculateTotal(arr), arr.length))
          }
        }

        this.criteriaScoresLocal.averagesTotal =
          this.criteriaScoresLocal.averagesPercentage = Math.round(
            calculateTotal(total)
          )
      } else {
        this.criteriaScoresLocal.averagesTotal =
          this.criteriaScoresLocal.averagesPercentage = Math.round(
            calculateTotal(this.criteriaScoresLocal.scores)
          )
      }
      this.criteriaScores = window._.cloneDeep(this.criteriaScoresLocal)
      this.getBoundaryByStudent()
    },
    async updateCriteriaScores(grade, add, typeOriginal) {
      const updateArr = (arr, el) => {
        const index = arr?.indexOf(el) || 0

        if (add) arr.push(el)
        else arr.splice(index, 1)
      }

      if (this.isMYPSection) {
        for (const key in grade) {
          const score = Number(grade[key])

          const criteriaKey = window._.cloneDeep(this.criteriaScoresLocal[key])
          await updateArr(criteriaKey.scores, score)
          this.criteriaScoresLocal[key] = window._.cloneDeep(criteriaKey)
        }
      } else if (!typeOriginal) {
        if (!this.criteriaScoresLocal.scores) {
          this.criteriaScoresLocal.scores = []
          const hasResource = this.allResources.some(
            (e) => !e.hide_from_grading_for_student
          )
          if (hasResource) {
            this.allResources.forEach((e) => {
              if (!e.hide_from_grading_for_student) {
                this.criteriaScoresLocal.scores.push(
                  e.applying_allocation_points
                )
              }
            })
          }
        }
        await updateArr(this.criteriaScoresLocal.scores, grade)
      } else {
        if (!this.criteriaScoresLocal?.scores[typeOriginal]) {
          await this.initCriteriaScore()
          await this.calculateCriteriaScores()
        }

        await updateArr(this.criteriaScoresLocal.scores[typeOriginal], grade)
      }
      this.calculateCriteriaScores()
    },

    ///////////////////////
    // Popup
    ///////////////////////
    activeUpdateStudentGrades() {
      this.allStudentsScore = null
      this.getStudentGrades()
    },
    resetSelectedResource() {
      this.allStudentsScore = null
      this.updateScoreStudentsData = []
    },

    closeUpdateStudentGrades() {
      this.resetSelectedResource()
      this.$emit('closeUpdateStudentGrades')
    },
    submitUpdateStudentGrades() {
      this.$validator.validateAll().then((valid) => {
        if (valid) this.updateGradeScores()
        else this.errMsg()
      })
    },
    ///////////////////////////
    // Submit
    ////////////////////////
    submitData() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (this.isStrandApproach) this.saveReportCardSubjectStrands()
          else this.createReportCard()
        } else {
          this.errMsg()
        }
      })
    },
    ///////////////////////
    // Check Data
    ///////////////////////

    checkCacheReportData() {
      if (this.reportCardData.assessmentsDataTable?.length) {
        this.data = this.reportCardData.assessmentsDataTable
        this.criteriaScoresLocal = this.criteriaScores
      } else if (this.isStrandApproach) this.getSubjectStrands()
      else this.getStudentGradingsByAllocation()
    }
  },
  created() {
    if (this.showManualUpdate)
      this.headers.push({
        field: 'manual_grade',
        title: this.$t('grading.manual_grade')
      })
  },
  mounted() {
    this.checkCacheReportData()
  }
}
</script>
<style lang="scss">
@import '@/modules/grading/components/gradeBookTable/gradingTable.scss';
#student-score-table {
  &:not(.no-data) .vs-con-tbody {
    // min-height: 300px;
    // max-height: 100%;
  }
  tr:nth-child(2n) {
    background-color: transparent;
  }
  .v-select {
    min-width: 190px;
  }
  .vs-input {
    min-width: 50px;
  }
  &.gradebook-table-style {
    th,
    td {
      max-width: 240px;
    }
    th:first-child,
    td:first-child {
      max-width: 30px;
      padding: 0;
    }
  }

  .criteria-cell {
    text-align: center;
    padding-inline: 0;

    &.td {
      padding: 0 !important;
      margin: 0 auto;
    }
    > span {
      text-align: center;
    }
    .criteria-key {
      padding: 10px 5px;
      width: inherit;
      &:not(:last-child) {
        border-right: 1px solid #ddd;
      }
    }
  }
}
</style>
<style scoped>
#student-score-table th {
  word-break: break-all;
}

#student-score-table.gradebook-table-style td {
  overflow: auto;
}
</style>
