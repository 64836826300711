<template>
  <div>
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>
    <h5 class="text-center mb-3">
      {{ $t('grading.update_poi') }}
    </h5>
    <div class="my-10">
      <template v-if="units.length > 0">
        <h6>
          {{ yearGroupName }}
        </h6>
        <template v-for="(unit, i) in units">
          <vs-checkbox
            class="my-2"
            :key="i"
            v-model="selectedUnits"
            :disabled="!isTeacherSectionCoodinator"
            :vs-value="unit.id">
            {{ unit.name }}
          </vs-checkbox>
        </template>
        <!--        <v-select-->
        <!--          :options="units"-->
        <!--          label="name"-->
        <!--          :reduce="(u) => u.id"-->
        <!--          class="w-full"-->
        <!--          :multiple="true"-->
        <!--          :placeholder="$t('grading.select_units')" />-->
        <vs-button
          class="vs-con-loading__container mt-10 mx-auto block"
          @click="submitData"
          ref="submitLoader"
          id="submitLoader"
          :disabled="loadSubmit || !isTeacherSectionCoodinator"
          >{{ $t('grading.click_to_save_after_teacher_review') }}
        </vs-button>
      </template>
      <template v-else>
        <p class="text-sm my-2 text-center" v-if="!loadDialog">
          {{ $t('grading.this_grade_has_not_POI_Units') | text_formatter }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
// Repos
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import POIRepo from '@/modules/poi/services/poi.js'

const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name: 'updatePOI',
  mixins: [gradingMixins],
  data: () => ({
    //////////////////
    // Data
    /////////////////
    units: [],
    gradeUnitGroup: {},
    selectedUnits: [],
    yearGroupName: ''
  }),
  computed: {
    gradeId() {
      return parseInt(
        this.selectedClass?.grade_id || this.$route.params.gradeId
      )
    }
    // selectedUnits: {
    //   get() {
    //     return this.reportCardData.unit_academics.map((e) => e.id)
    //   },
    //   set(v) {
    //     console.log(v)
    //     this.reportCardData.unit_academics.push({id: ...v})
    //   }
    // }
    // units() {
    //   return this.gradeUnitGroup?.units || []
    // },
  },
  methods: {
    ///////////////////////
    // API
    ////////////////////////
    getUnits() {
      if (!this.gradeId) return
      this.startLoadingDialog()
      POIRepo.getGradeUnits(this.gradeId)
        .then((res) => {
          this.handleUnits(res.data)
          this.getPOIUnitsOfRoundGrade()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    getPOIUnitsOfRoundGrade() {
      this.startLoadingDialog()
      ReportCardRepo.getPOIUnitsOfRoundGrade(
        this.gradeId,
        this.activeRoundGradingId
      )
        .then((res) => {
          res.data.forEach((e) => {
            const existUnit = this.units.some(
              (u) => u.id === e.unit_academic_id
            )
            if (existUnit) this.selectedUnits.push(e.unit_academic_id)
          })
          this.handleCacheData()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    savePOIOfSubject() {
      this.startLoadingDialog()
      const payload = {
        round_id: this.activeRoundGradingId,
        user_id: this.selectedStudentUserId,
        subject_id: this.selectedSubjectId,
        unit_academic_ids: this.selectedUnits
      }
      ReportCardRepo.savePOI(payload)
        .then((res) => {
          this.successMsg()
          this.reportCardData.unit_academics = this.selectedUnits
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    saveData() {
      this.startLoadingDialog()
      ReportCardRepo.savePOIUnitsOfRoundGrade(
        this.gradeId,
        this.activeRoundGradingId,
        this.selectedUnits
      )
        .then((res) => {
          this.successMsg()
          this.handleCacheData()
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    ///////////////////////////
    // Handle Data
    ////////////////////////////
    handleUnits(data) {
      // this.gradeUnitGroup = data.find((u) => u.grade_id === this.gradeId)
      this.yearGroupName = data.name
      this.units = data.units
      this.reportCardData.POI = {
        yearGroupName: this.yearGroupName,
        units: this.units
      }
    },
    handleCacheData() {
      this.reportCardData.POI.selectedUnits = this.selectedUnits
    },
    // /////////////////////////
    // Submit
    ////////////////////////////
    submitData() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveData()
        } else {
          this.errMsg()
        }
      })
    }
  },
  created() {
    this.selectedUnits =
      this.reportCardData?.unit_academics?.map((e) => e.id) ||
      this.reportCardData?.unit_academics ||
      []

    if (this.reportCardData?.POI?.units) {
      const POI = this.reportCardData.POI
      this.yearGroupName = POI.yearGroupName
      this.units = POI.units
      if (POI?.selectedUnits.length > 0) this.selectedUnits = POI.selectedUnits
      else this.getPOIUnitsOfRoundGrade()
    } else this.getUnits()
  }
}
</script>
